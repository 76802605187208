<template>
  <div class="program">
    <div class="program-header">
      <div class="header-left">
        <el-button icon="el-icon-arrow-left" size="small" @click="$router.back()">返回</el-button>
      </div>
      <!-- <div class="prev-next">
        <el-button icon="el-icon-arrow-left" size="small" @click="prevQuestion()">上一题</el-button>
        <el-button size="small" @click="nextQuestion()">下一题<i class="el-icon-arrow-right"></i></el-button>
      </div> -->
      <div class="header-right">
        <!-- <el-button icon="el-icon-tickets" size="small" @click="drawerShow = true">试题列表</el-button> -->
      </div>
    </div>
    <div class="program-main" v-loading="loading">
      <div class="left" ref="left">
        <div class="title">{{ questionInfo.title }}</div>
        <div class="details">
          <div class="details-item"
            :style="{ color: questionInfo.level === '1' ? '#26bb9c' : questionInfo.level === '2' ? '#efc100' : '#ea4e07' }">
            {{
              levelObj[questionInfo.level] }}</div>
          <!-- <div class="details-item">通过率：50%</div> -->
        </div>
        <!-- <div class="knowledge-points">
          <div class="knowledge-points-title">知识点</div>
          <div class="knowledge-points-item">知识点1</div>
        </div> -->
        <div class="describe">
          <div class="describe-title">描述</div>
          <div class="describe-desc" v-html="questionInfo.name"></div>
        </div>
        <!-- <div class="input">
          <div class="input-title">输入</div>
          <div class="input-content">1234</div>
        </div>
        <div class="output">
          <div class="output-title">输出</div>
          <div class="output-content">true</div>
        </div> -->
      </div>
      <div class="line" ref="line" @mousedown="md">
        <i class="el-icon-more"></i>
      </div>
      <div class="right" ref="right">
        <div class="right-top">
          <el-select v-model="editOption.language" placeholder="选择编程语言" @change="changeLanguage" size="small">
            <el-option v-for="item in languageList" :key="item?.experiment?.id" :label="item?.experiment?.code_run_cmd" :value="item?.experiment?.id">
            </el-option>
          </el-select>
        </div>
        <div ref="editor" class="editor"></div>
        <div class="right-bottom" :class="isFold ? 'Fold' : ''">
          <div class="control">
            <div style="display: flex; align-items: center;">
              <div class="btn" :class="item.id === activeControlId ? 'btn-active' : ''" v-for="item in controls"
                :key="item.id" @click="changeControl(item.id)">{{ item.name }}</div>
            </div>
            <div>
              <el-button class="btn-submit" type="primary" size="small" plain @click="getCodeResult()"
                :loading="runLoading">保存并执行</el-button>
            </div>
            <div class="el-icon-arrow-down" :class="isFold ? 'isfold' : ''" @click="foldControl()"></div>
          </div>
          <div class="resultData" v-if="activeControlId==='1'">
            {{ resultData }}
          </div>
        </div>
      </div>
    </div>

    <el-drawer :visible.sync="drawerShow" :size="800">
      <div class="drawer-title" slot="title">
        <div class="drawer-title-text">试题列表</div>
      </div>
      <div>
        <div class="question-list">
          <div class="question-item" v-for="(item, index) in questionContentList" :key="index"
            @click="changeQuestion(index)">
            <span class="question-item-drop" :class="index === questionIndex ? 'current-ques' : ''"></span>{{ item.file }}
          </div>
        </div>
      </div>
    </el-drawer>

  </div>
</template>

<script>
import * as monaco from "monaco-editor";
export default {
  name: "ProgramView",
  data() {
    return {
      // 页面加载状态
      loading: true,
      // 编辑器实例
      editor: null,
      editOption: {
        value: "",
        // 编程语言
        language: "",
        // 字体大小
        fontSize: 16,
        // 自动布局
        automaticLayout: true,
        // 主题颜色
        theme: "vs-dark",
        // 上下文菜单
        contextmenu: true,
        // 内联颜色
        colorDecorators: true,
      },
      languageList: [

      ],
      copy:[
      {
          id: "javascript",
          label: "JavaScript"
        },
        {
          id: "php",
          label: "PHP"
        },
        {
          id: "python",
          label: "Python"
        },
        {
          id: "java",
          label: "Java"
        }
      ],

      position: {
        x: 0,
        move: false,
      },

      // 路由参数
      routeQuery: {
        // 实验id
        experiment_id: "",
        // 试题id
        data_id: ""
      },
      // 试题难度列表
      levelObj: {},
      // 试题信息
      questionInfo: {},
      questionContentList: [],
      questionIndex: 0,
      // 编辑器当前的值
      codeValue: "",

      // 控制区域功能
      controls: [
        {
          name: "执行结果",
          id: "1"
        },
        {
          name: "执行记录",
          id: "2"
        },
      ],
      // 激活的控制区域
      activeControlId: "1",
      // 控制区域是否折叠状态
      isFold: false,
      // 执行按钮加载状态
      runLoading: false,
      // 运行结果数据
      resultData:"",

      // 试题列表抽屉可见性
      drawerShow: false
    }
  },
  methods: {
    // 获取试题信息
    getQuestionInfo() {
      const { routeQuery } = this;
      this.loading = true;
      this.api.dataset.getQuestionInfo(routeQuery).then(res => {
        console.log("试题信息",res);
        this.languageList=res?.data_experiment_list||[];
        this.levelObj = res?.data_levels || {};
        this.questionInfo = res?.data_info || {};
        res?.code_files.forEach(item => {
          const index = item.show_url.indexOf(".cn");
          if (index !== -1) {
            item.show_url = item.show_url.substring(index + 3);
          }
        });
        this.questionContentList = res?.code_files || [];
        // 获取第一道题的数据
        this.questionIndex = 0;
        this.getQuestionContent(0);
      });
    },
    // 获取试题内容
    getQuestionContent(index) {
      const { questionContentList, editOption, routeQuery } = this;
      this.loading = true;
      this.api.dataset.getQuestionContent(questionContentList[index].show_url).then(res => {
        const extIndex = questionContentList[index].file.lastIndexOf(".") + 1;
        const extension = questionContentList[index].file.substring(extIndex);
        // editOption.language = extension;
        editOption.language = routeQuery.experiment_id || this.languageList[0].experiment.id;
        this.codeValue = res;
        // this.changeLanguage(extension);
        // 编辑器赋值
        const model = monaco.editor.createModel(this.codeValue, extension);
        this.editor.setModel(model);
        this.loading = false;
      });
    },

    // 拖动中间线条改变两侧大小
    md(e) {
      const { position } = this;
      position.x = e?.target?.offsetLeft || 0;
      position.move = true;
    },
    mv(e) {
      const { position } = this;
      if (!position.move) return;
      position.x = e.pageX;
      this.$refs.left.style.width = `${e.pageX}px`;
      this.$refs.right.style.width = "0px";
      this.editor.layout();
    },
    mp() {
      const { position } = this;
      position.move = false;
    },


    // 初始化编辑器
    initEditor() {
      const { editOption } = this;
      this.editor = monaco.editor.create(this.$refs.editor, editOption);
      // 监听内容变化实时赋值
      this.editor.onDidChangeModelContent(() => {
        this.codeValue = this.editor.getValue();
      });
    },
    // 改变语言
    changeLanguage(value) {
      const { codeValue,routeQuery } = this;
      // const model = monaco.editor.createModel(codeValue, value);
      // this.editor.setModel(model);
      routeQuery.experiment_id=value;
      this.getQuestionInfo();
    },
    // 折叠展开控制区
    foldControl() {
      this.isFold = !this.isFold;
      if (this.isFold === false) {
        this.$refs.editor.style.height = "0px";
        this.editor.layout();
      }
    },
    // 展开区域
    expandControl(){
      this.isFold=false;
      this.$refs.editor.style.height = "0px";
      this.editor.layout();
    },
    changeControl(id) {
      this.activeControlId = id;
    },
    // 保存代码
    saveCode() {
      const { questionContentList, questionIndex, routeQuery, codeValue } = this;
      return new Promise((resolve, reject) => {
        this.api.dataset.experimentSaveCode({
          ...routeQuery,
          file: questionContentList[questionIndex].file,
          content: codeValue
        }).then(() => {
          resolve();
        }).catch(() => {
          reject("保存代码错误");
        });
      });
    },
    // 服务器执行代码
    runCode() {
      const { questionContentList, questionIndex, routeQuery } = this;
      return new Promise((resolve, reject) => {
        this.api.dataset.experimentRunCode({
          ...routeQuery,
          file: questionContentList[questionIndex].file
        }).then(res => {
          resolve(res);
        }).catch(() => {
          reject("执行代码错误");
        });
      });
    },
    // 获取执行完毕代码的结果
    getRunningResults(outputRes) {
      let url = outputRes.output_file;
      const index = url.indexOf(".cn");
      if (index !== -1) {
        url = url.substring(index + 3);
      }
      return new Promise((resolve, reject) => {
        this.api.dataset.getQuestionContent(url).then(res => {
          resolve(res);
        }).catch(() => {
          reject("获取运行结果错误");
        });
      });
    },
    // 执行代码
    async getCodeResult() {
      this.runLoading = true;
      this.expandControl();
      this.resultData="";
      // 执行代码需要先等待保存代码
      await this.saveCode();
      // 等待代码在服务器运行
      const outputRes = await this.runCode();
      // 获取代码运行结果
      const res = await this.getRunningResults(outputRes);
      console.log("运行结果", res);
      this.resultData=res;
      this.runLoading = false;
    },
    // 切换试题
    changeQuestion(index) {
      if (index === this.questionIndex) {
        this.drawerShow = false;
        return;
      }
      this.questionIndex = index;
      this.getQuestionContent(index);
      this.drawerShow = false;
    },
    // 上一题
    prevQuestion() {
      const { questionIndex } = this;
      if (questionIndex === 0) {
        this.$Message.info("已经是第一题了");
        return;
      }
      this.getQuestionContent(questionIndex - 1);
    },
    // 下一题
    nextQuestion() {
      const { questionContentList, questionIndex } = this;
      if (questionContentList.length === questionIndex + 1) {
        this.$Message.info("已经是最后一题了");
        return;
      }
      this.getQuestionContent(questionIndex + 1);
    }
  },
  created() {
    const { query } = this.$route;
    this.routeQuery = query;
    this.getQuestionInfo();
  },
  mounted() {
    window.addEventListener("mousemove", this.mv);
    window.addEventListener("mouseup", this.mp);
    this.initEditor();
  },
  beforeDestroy() {
    window.removeEventListener("mousemove", this.mv);
    window.removeEventListener("mouseup", this.mp);
    this.saveCode();
    this.editor.dispose();
  }
}
</script>

<style lang="scss" scoped>
.program {
  height: 100vh;
  overflow: hidden;
  background-color: white;

  .program-header {
    height: 60px;
    box-sizing: border-box;
    background-color: white;
    border-bottom: 10px solid #f7f8f9;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
  }

  .program-main {
    height: calc(100% - 70px);
    display: flex;
    overflow: auto;

    .left {
      width: 40%;
      overflow-y: auto;
      padding: 20px;
      box-sizing: border-box;

      .title {
        font-size: 20px;
        color: #333;
        font-weight: bold;
        margin-bottom: 10px;
      }

      .details {
        display: flex;
        color: #666;
        font-size: 16px;
        margin-bottom: 20px;

        .details-item {
          margin-right: 10px;
        }
      }

      .knowledge-points {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        .knowledge-points-title {
          font-size: 16px;
          font-weight: bold;
          color: #333;
        }

        .knowledge-points-item {
          padding: 2px 5px;
          border-radius: 20px;
          color: #666;
          margin-left: 10px;
          background-color: #f7f8f9;
          border: 1px solid #e3e3e6
        }
      }

      .describe {
        margin-bottom: 20px;

        .describe-title {
          font-size: 18px;
          color: #333;
          font-weight: bold;
        }

        .describe-desc {
          font-size: 16px;
        }
      }

      .input {
        margin-bottom: 20px;

        .input-title {
          font-size: 18px;
          color: #333;
          font-weight: bold;
          margin-bottom: 5px;
        }

        .input-content {
          background-color: #f7f8f9;
          border-radius: 4px;
          padding: 20px;
          font-size: 16px;
        }
      }

      .output {
        margin-bottom: 20px;

        .output-title {
          font-size: 18px;
          color: #333;
          font-weight: bold;
          margin-bottom: 5px;
        }

        .output-content {
          background-color: #f7f8f9;
          border-radius: 4px;
          padding: 20px;
          font-size: 16px;
        }
      }
    }

    .line {
      width: 10px;
      transition: .3s;
      background-color: #f7f8f9;
      cursor: move;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      color: #686868;

      i {
        transform: rotate(90deg);
      }

      &:hover {
        background-color: #dfdfdf;
      }
    }

    .right {
      flex-grow: 1;
      width: 50%;
      display: flex;
      flex-direction: column;

      .right-top {
        padding: 10px;
      }

      .editor {
        width: 100%;
        flex-grow: 1;
      }

      .right-bottom {
        height: 300px;

        .control {
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid #f3f3f3;
          padding: 10px;
          position: relative;

          .el-icon-arrow-down {
            position: absolute;
            left: 50%;
            top: -10px;
            font-size: 18px;
            font-weight: bold;
            width: 25px;
            height: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: white;
            border-radius: 50%;
            box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.4);
            cursor: pointer;

            &:hover {
              color: #4E83FF;
            }
          }

          .isfold {
            transform: rotate(180deg);
          }

          .btn {
            margin-right: 10px;
            font-size: 14px;
            cursor: pointer;
            padding: 5px 6px;
          }

          .btn-active {
            color: #4E83FF;
            background-color: #4e83ff38;
            border-radius: 3px;
          }

          .btn-submit {
            background-color: #ecf5ff !important;
            color: #409eff;

            &:hover {
              background-color: #4e83ff3c !important;
            }
          }
        }
        .resultData{
          padding: 20px;
          font-size: 20px;
        }
      }

      .Fold {
        height: 50px !important;
      }
    }
  }

  .question-list {
    padding: 20px 0;

    .question-item {
      font-size: 20px;
      font-weight: bold;
      color: #555;
      padding: 5px 30px;
      cursor: pointer;
      display: flex;
      align-items: center;

      &:hover {
        background-color: #f7f8f9;
      }

      .question-item-drop {
        margin-right: 15px;
        height: 10px;
        width: 10px;
        border-radius: 50%;
        background-color: rgb(182, 182, 182);
      }

      .current-ques {
        background-color: #4E83FF;
      }
    }
  }
}
</style>